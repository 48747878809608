const RowDetail = ({ label, value, isLink = false, href = null, underline = false, action = null }) => {
  return (
    <div className="item-detail_content_info-product_table_left_row">
      <div className="item-detail_content_info-product_table_left_row_left">
        <p>{label}</p>
        <span>:</span>
      </div>
      <div className={`item-detail_content_info-product_table_left_row_right ${underline ? 'underline' : ''}`}>
        {isLink ? (
          <a href={href} target="_blank">
            {value}
          </a>
        ) : (
          value
        )}
      </div>
      {action && <div className="item-detail_content_info-product_table_left_row_editTracking">{action}</div>}
    </div>
  );
};

export default RowDetail;
